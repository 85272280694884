import { IPageWillMountHandler, IPropsStore, IStructureAPI } from '@wix/thunderbolt-symbols'
import type { SospPageConfig } from './types'
import { resolveSospOnPage } from './sospApi'

export const Sosp = (
	sospConfig: SospPageConfig,
	structureApi: IStructureAPI,
	propsStore: IPropsStore
): IPageWillMountHandler => ({
	async pageWillMount(pageId) {
		resolveSospOnPage(pageId, sospConfig, structureApi, propsStore)
	},
})
