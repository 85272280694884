import { ContainerModuleLoader, named, withDependencies } from '@wix/thunderbolt-ioc'
import { LifeCycle, PageFeatureConfigSymbol, Props, StructureAPI } from '@wix/thunderbolt-symbols'
import { Sosp } from './sosp'
import { name } from './symbols'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler).to(
		withDependencies([named(PageFeatureConfigSymbol, name), StructureAPI, Props], Sosp)
	)
}
